<template>
	<div class="pages">
		<div class="pages-top f f-lv-s">
			<div class="pages-top-title">CitiGroup</div>
			<div class="pages-top-right" @click="tolg">{{$t('系统语言')}}</div>
		</div>
		<div class="pages-top-zw"></div>
		<div class="pages-swiper">
			<van-swipe :autoplay="3000" :show-indicators="false" style="width: 100%;height: 100%;">
				<van-swipe-item v-for="(item,index) in banners" :key="index">
					<img :src="item.url" class="pages-swiper-img" />
				</van-swipe-item>
			</van-swipe>
			<!-- <div class="pages-jpm f fw-l-c">
				<div class="pages-jpm-title">{{$t('实现负责任的增长')}}</div>
				<div class="pages-jpm-text">{{$t('在摩根士丹利，我们秉承一个共同的目标：充分利用每一个联系的力量，帮助人们改善金融生活')}}</div>
			</div> -->
		</div>
		<div class="pages-list f fw">
			<div class="pages-list-item f fw-v-c" v-for="(item,index) in lotteryitem" :key="index" @click="openPage(index)">
				<div class="pages-list-item-cover">
					<img :src="item.icon" />
				</div>
				<div class="pages-list-item-title">{{item.name}}</div>
				<div class="pages-list-item-text">{{$t('索引')}} {{item.points}}</div>
			</div>
		</div>
		<div class="page-user">
			<div class="page-user-top f f-v-c">
				<div>{{$t('交易用户')}}</div>
				<div>{{$t('交易类别')}}</div>
				<div>{{$t('一次性盈利')}}</div>
				<div>{{$t('交易日期')}}</div>
			</div>
			<!-- <swiper class="user_swiper" :options="bannerSwiperOption">
				<swiper-slide v-for="(item,index) in userGameList" :key="index">
					<div class="user_swiper-item f f-v-c">
						<div class="user_swiper-item-name">{{item.user}}</div>
						<div class="user_swiper-item-lb">{{item.category}}</div>
						<div class="user_swiper-item-name">{{item.profit}}</div>
						<div class="user_swiper-item-rq">{{item.date}}</div>
					</div>
				</swiper-slide>
			</swiper> -->
			<vue-seamless-scroll :class-option="classOption" :data="userGameList" class="user_swiper">
				<div>
					<div class="user_swiper-item f f-v-c" v-for="(item, index) in userGameList" :key="index">
						<div class="user_swiper-item-name">{{item.user}}</div>
						<div class="user_swiper-item-lb">{{item.category}}</div>
						<div class="user_swiper-item-name">{{item.profit}}</div>
						<div class="user_swiper-item-rq">{{item.date}}</div>
					</div>
				</div>
			</vue-seamless-scroll>
		</div>
		<div class="pages-zw"></div>
	</div>
</template>

<script>
	import vueSeamlessScroll from 'vue-seamless-scroll'
	export default {
		components: {
		  vueSeamlessScroll
		},
		data() {
			return {
				basicData: {},
				banners: [],
				notice: '',
				lotteryitem: [],
				lotteryList: [{
						name: '',
						list: []
					},
					{
						name: '',
						list: []
					}
				],
				bannerSwiperOption: {
					slidesPerView: 5,
					speed: 500,
					autoplay: {
						disableOnInteraction: false,
						delay: 3000 //3秒切换一次
					},
					direction: 'vertical',
				},
				userGameList: [],
				menuList: [
					'/img/home/tzhb-m0.jpg',
					'/img/home/tzhb-m1.jpg',
					'/img/home/tzhb-m2.jpg',
					'/img/home/tzhb-m3.jpg',
					'/img/home/tzhb-m4.jpg',
					'/img/home/tzhb-m5.jpg'
				]
			}
		},
		computed: {
			classOption() {
				return {
					step: 0.2, // 数值越大速度滚动越快
					// limitMoveNum: 10, // 开始无缝滚动的数据量 this.dataList.length
					hoverStop: true, // 是否开启鼠标悬停stop
					direction: 1, // 0向下 1向上 2向左 3向右
					// openWatch: true, // 开启数据实时监控刷新dom
					singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
					singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
					waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
				};
			},
		},
		mounted() {
			this.getAllCarouselList()
			this.getAllItemlist()
			this.getBasicConfig()
		},
		methods: {
			tolg() {
				this.$router.push("/Language")
			},
			/* 获取首页列表轮播 */
			getAllCarouselList() {
				this.$http({
					method: 'get',
					url: 'getAllGameList'
				}).then(res => {
					// console.log('获取首页轮播', res)
					this.userGameList = res.data
				})
			},
			/* 获取首页国旗菜单 */
			getAllItemlist() {
				this.$http({
					method: 'get',
					url: 'getItemlist'
				}).then(res => {
					// console.log('获取首页国旗', res)
					this.lotteryitem = res.data;
				})
			},
			getBasicConfig() {
				this.$http({
					method: 'get',
					url: 'sys_config'
				}).then(res => {
					this.basicData = res.data;
					this.notice = this.basicData.notice //获取公告
					this.banners = this.basicData.banners //获取banner
					// console.log('首页轮播',this.basicData)
				})
			},
			openPage(index){
				this.$router.push('/Game?tabIndex=' + index )
			}
		}
	}
</script>

<style lang="less" scoped>
	.pages {
		width: 100%;
		height: 100vh;
		overflow-y: auto;

		.pages-top {
			position: fixed;
			top: 0;
			width: 100%;
			z-index: 100;
			padding: 0 40px;
			height: 100px;
			background-color: #fff;

			.pages-top-title {
				font-size: 40px;
				color: #333;
				font-weight: bold;
			}

			.pages-top-right {
				font-size: 24px;
				color: #ccc;
			}
		}

		.pages-top-zw {
			height: 100px;
		}
		
		.pages-swiper {
			position: relative;
			box-sizing: border-box;
			// height: 500px;
			min-height: 200px;
			margin: 0 40px;
			overflow: hidden;
			background-color: #f6f7f9;
		
			.pages-swiper-img {
				width: 100%;
				height: 100%;
				object-fit: contain;
				border-radius: 15px;
			}
		}

		.pages-jpm {
			position: absolute;
			width: 95%;
			top: 0;
			height: 100%;
			.pages-jpm-title {
				// margin-top: 170px;
				text-align: right;
				font-size: 27px;
				color: #000;
			}

			.pages-jpm-text {
				margin-top: 10px;
				text-align: right;
				font-size: 20px;
				color: #000;
			}
		}

		.pages-list {
			min-height: 200px;
			margin-top: 40px;
			box-sizing: border-box;
			padding: 0 40px;

			.pages-list-item {
				box-sizing: border-box;
				width: calc(33.33% - 17px);
				margin-right: 23px;
				margin-bottom: 23px;
				border: 1px solid #eee;
				border-radius: 15px;
				padding: 25px 0 25px 0;

				.pages-list-item-cover {
					width: 60px;
					height: 60px;
					border-radius: 50%;
					overflow: hidden;

					img {
						width: 100%;
						height: 100%;
					}
				}

				.pages-list-item-title {
					font-size: 26px;
					margin-top: 10px;
					color: #000;
				}

				.pages-list-item-text {
					font-size: 21px;
					margin-top: 5px;
					color: #aaa;
				}
			}

			.pages-list-item:nth-of-type(3) {
				margin-right: 0;
			}

			.pages-list-item:nth-of-type(6) {
				margin-right: 0;
			}
		}

		.pages-title {
			padding: 35px 30px;
			background-color: #fafafa;

			.pages-title-text {
				font-size: 35px;
				margin: 0 10px;
				color: #333;
				font-weight: bold;
			}

			.pages-title-mark {
				width: 5px;
				background-color: #e6241a;
				height: 20px;
				opacity: 0.5;
				margin: 0 5px;
			}

			.pages-title-mark1 {
				width: 6px;
				background-color: #e6241a;
				height: 30px;
			}
		}

	}

	.page-user {
		margin-top: 40px;
		padding: 0 40px;
		box-sizing: border-box;

		.page-user-top {
			margin-bottom: 25px;

			div {
				font-size: 21px;
				color: #ccc;
				flex: 1;
				text-align: center;
			}
		}
	}

	.user_swiper {
		width: 100%;
		height: 500px;
		overflow: hidden;
		.user_swiper-item {
			background-color: #f6f7f9;
			width: 100%;
			// height: 100%;
			box-sizing: border-box;
			padding: 15px 0;
			overflow: hidden;
			border-radius: 5px;
			margin-bottom: 7px;

			div {
				flex: 1;
				text-align: center;
			}

			.user_swiper-item-name {
				font-size: 26px;
				color: #000;
			}

			.user_swiper-item-lb {
				font-size: 26px;
				color: #999;
			}

			.user_swiper-item-rq {
				font-size: 22px;
				color: #ccc;
			}
		}
	}

	.pages-zw {
		height: 120px;
	}

	::v-deep .van-notice-bar__left-icon {
		font-size: 30px;
	}

	::v-deep .swiper-slide {
		height: 70px !important;
	}
</style>