<template>
	<div class="morgan-stanley">
		<header>
			<div class="pages-top f f-lv-s">
				<div class="pages-top-title">CitiGroup</div>
			</div>
			<div class="pages-top-zw"></div>
		</header>
			<section class="hero-section">
				<img src="/img/home/tzhb-tp5.jpg" alt="Building Image" class="building-image">
			</section>
		<div class="mainBox">
			<div class="mainBox-title">{{$t('介绍')}}</div>
			<div class="mainBox-text">{{$t('自1935年成立以来，摩根士丹利始终以一流的方式提供一流的业务。我们所做的一切都以五大核心价值观为基础。摩根士丹利的领导层致力于以一流的方式开展一流的业务。我们的董事会和执行董事相信资本可以而且应该造福整个社会')}}</div>
			
			<div class="mainBox-imgList f">
				<div class="mainBox-image">
					<img src="/img/home/tzhb-tp6.jpg" alt="Building Image">
				</div>
				<div class="mainBox-image">
					<img src="/img/home/tzhb-tp3.jpg" alt="Building Image">
				</div>
			</div>
			<div class="mainBox-text">{{$t('摩根士丹利所做的一切都遵循我们的五大核心价值观：做正确的事顾客至上用伟大的想法引领我们致力于多元化和包容性，并奖励摩根士丹利，我们因团队的多元化人才而出名。我们的开放和包容文化创造了我们的传统并塑造了我们的未来。这有助于加强我们的业务并为我们的客户带来价值')}}</div>
			
			<div class="mainBox-title">{{$t('我们该做什么')}}?</div>
			<div class="mainBox-text">{{$t('在摩根士丹利，客户是第一位的。我们帮助个人、家庭、机构和政府筹集、管理和分配实现目标所需的资本。探索摩根士丹利我们帮助个人、企业和机构创造、保存和管理财富。为了帮助他们实现他们的财务目标，摩根士丹利以伟大的想法引领他们。我们对当今所有业务领域中最重要的问题提供了敏锐的见解')}}</div>
		</div>
		<div class="pages-zw"></div>
	</div>
</template>

<script>
	export default {
		name: 'MorganStanley'
	};
</script>

<style scoped lang="less">
	.morgan-stanley {
		font-family: Arial, sans-serif;
		width: 100%;
		height: 100vh;
		overflow-y: auto;
	}
	
	.pages-zw {
		height: 150px;
	}

	.pages-top {
		position: fixed;
		top: 0;
		width: 100%;
		z-index: 100;
		padding: 0 40px;
		height: 100px;
		background-color: #fff;
	
		.pages-top-title {
			font-size: 40px;
			color: #333;
			font-weight: bold;
		}
	
		.pages-top-right {
			font-size: 24px;
			color: #ccc;
		}
	}
	.pages-top-zw {
		height: 100px;
	}

	.logo {
		height: 30px;
	}

	.date {
		color: #6c757d;
	}

	.hero-section {
		text-align: center;
	}

	.building-image {
		width: 100%;
		height: auto;
	}
	.mainBox{
		padding: 0 40px;
		box-sizing: border-box;
		
		.mainBox-title{
			margin-top: 50px;
			font-size: 32px;
			color: #000;
		}
		
		.mainBox-text{
			margin-top: 10px;
			font-size: 22px;
			color: #aaa;
		}
		.mainBox-imgList{
			position: relative;
			margin-top: 50px;
			width: 100%;
			height: 200px;
			border-radius: 10px;
			overflow: hidden;
		}
		.mainBox-image{
			position: relative;
			flex: 1;
			img{
				width: 100%;
				height: 100%;
			}
		}
	}
	
</style>