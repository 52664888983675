<template>
  <div class="container page">
    <van-nav-bar :title="$t('提现中心')" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()"/>
      </template>
      <template #right>
        <span class="nav-right" @click="$router.push({path:'/WithdrawRecord'})">{{ $t("提现记录") }}</span>
      </template>
    </van-nav-bar>
    <div class="main">
      <div class="withdrawMoney">
        <span>{{ $t("提现金额 (元)") }}</span>
        <div class="money">
          <div class="moneyNumber">
            <span class="moneyType"></span>
            <van-field v-model="withdraw_money" type="number" />
          </div>
          <span class="all" @click="allMoeny()">{{ $t("全部") }}</span>
        </div>
        <div class="information">
          <div class="description" style="display: none;">
            <van-popover v-model="showPopover" trigger="click">
              <div class="popover-body" style="padding: 10px;">
                <p>1.{{ $t("单笔限额：最低") }}{{this.withdrawRole.min}}{{ $t("元，最高") }}{{this.withdrawRole.max}}{{ $t("元") }}</p>
                <p>2.{{ $t("提现次数：一天最高提现") }}{{this.withdrawRole.num}}{{ $t("次") }}</p>
                <p>3.{{ $t("到账时间：一般到账时间在5分钟左右，最快2分钟内到账") }}</p></div>
              <template #reference @click="withdrawInfo()">
                <van-icon name="info-o" />
                {{ $t("限额说明") }}
              </template>
            </van-popover>
          </div>

          <div class="balance" style="margin-top: 11px;">
            <span>{{ $t("余额") }}：</span>
            <span class="number">{{ this.userInfo.money }}{{ $t("元") }}</span>
          </div>
        </div>
      </div>
	  
	  <div class="main-box">
	    <div class="label" style="height: 29px;margin-top: 9px;">{{ $t("请输入您的收款卡信息") }}</div>
	    <van-cell-group v-if="ifwrite">
	  	  <van-field v-model="name" :label="$t('姓名')"  :placeholder="$t('请输入真实姓名')" />
	      <van-field v-model="bankid" :label="$t('银行卡号')"  :placeholder="$t('请输入真实银行卡号')" />
	  	<van-field v-model="bank" :label="$t('银行名称')" :placeholder="$t('请输入真实银行名称')" />
	      <!-- <van-field v-model="bank" :label="$t('银行名称')" readonly :placeholder="$t('请选择银行')" @click="showSelectBanks()"/> -->
	    </van-cell-group>
		<van-cell-group v-else>
		  <van-field v-model="name" :label="$t('姓名')" readonly  :placeholder="$t('请输入真实姓名')" />
		  <van-field v-model="bankid" :label="$t('银行卡号')" readonly  :placeholder="$t('请输入真实银行卡号')" />
		<van-field v-model="bank" :label="$t('银行名称')" readonly :placeholder="$t('请输入真实银行名称')" />
		  <!-- <van-field v-model="bank" :label="$t('银行名称')" readonly :placeholder="$t('请选择银行')" @click="showSelectBanks()"/> -->
		</van-cell-group>
	    <!-- <p>{{ $t("尊敬的用户,为了保障您的资金安全,请您绑定您的真实姓名和设置取款密码,如果姓名与开户名不一致,将无法取款") }}</p> -->
	  </div>
	  
      <van-button class="withdraw_btn" type="default" @click="doWithdraw()">{{ $t("马上提现") }}</van-button>

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showPopover: false,
      withdraw_money:"",
      userInfo:{},
	  bankid:"",
	  name:"",
	  bankcode:"",
	  bank:"",
	  ifwrite:true,
      withdrawRole:{}
    };
  },
  methods: {
    back(){
      return window.history.back();
    },
    getUserInfo(){
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res=>{
        if(res.code === 200){
          this.userInfo = res.data;
          this.name = res.data.name;
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
	getUserBankInfo(){
	  this.$http({
	    method: 'get',
	    url: 'user_get_bank'
	  }).then(res=>{
	    if(res.code === 200){
	      if(res.data.is_bank){
	        this.bankid = res.data.info.bankid;
	        this.bank = res.data.info.bankinfo;
			this.ifwrite =false;
	      }else {
			  this.name = "";
			  this.bankid = "";
			  this.bank ="";
	        // this.is_bind = false;
	      }
	    }else if(res.code ===401){
	      // this.$toast(res.msg);
	    }
	  })
	},
    getUserWithdrawRole(){
      this.$http({
        method: 'get',
        url: 'user_get_withdraw_role'
      }).then(res=>{
        if(res.code === 200){
          this.withdrawRole = res.data;
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
    allMoeny(){
      this.withdraw_money = this.userInfo.money;
    },
    doWithdraw(){
      if(this.withdraw_money <= 0){
        this.$toast(this.$t('请填写正确的金额'));
        return false;
      }else {
		  
		  if(this.bankid === "" || this.bankid === null || this.bankid === undefined){
		    this.$toast(this.$t('请输入银行卡号！'));
		    return true;
		  }
		  
		  if(this.name === "" || this.name === null || this.name === undefined){
		    this.$toast.fail(this.$t('请输入真实姓名'));
		    return false;
		  }
		  
		  if(this.bank === "" || this.bank === null || this.bank === undefined){
		    this.$toast.fail(this.$t('请输入真实银行名称'));
		    return false;
		  }
		  
		  
        this.$http({
          method: 'post',
          data:{money:this.withdraw_money,name:this.name,bankid:this.bankid,bank:this.bank},
          url: 'user_set_withdraw'
        }).then(res=>{
          if(res.code === 200){
            // location.reload()
            this.$toast(this.$t('提现成功'));
            this.getUserInfo();
            this.getUserWithdrawRole();
			setTimeout(()=>{
				this.$router.push('/Moneylog')
			},1200)
          }else if(res.code ===401){
            this.$toast(res.msg);
          }
        })
      }
    },
    withdrawInfo(){
      this.showPopover = true;
    }
  },
  created() {
    if(!localStorage.getItem('token')){
      this.$router.push({path:'/Login'})
    }else {
      this.getUserInfo();
      this.getUserWithdrawRole();
	  this.getUserBankInfo();
    }
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";
.van-cell {
  font-size: 35px;
  line-height: 80px;
}
.container p{
  padding: 0 15px;
  margin-top: 15px;
  font-size: 30px;
  color: #dc2037;
}
.container .main{
  display: flex;
  flex-direction: column;
  background-color: #f2f2f5;
  height: calc(100% - 50px);
  position: relative;
}
.container .main .withdrawMoney{
  display: flex;
  flex-direction: column;
  color: #000;
  padding: 0 20px;
  white-space: nowrap;
  font-size: 35px;
  background-color: #fff;
}
.container .main .withdrawMoney span {
  padding: 10px 0;
}
.container .main .withdrawMoney .money{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #f2f2f5;
}
.container .main .withdrawMoney .money .moneyNumber{
  font-size: 50px;
  display: flex;
  flex-direction: row;
}
.container .main .withdrawMoney span {
  padding: 10px 0;
}
.container .main .withdrawMoney .money .all{
  color: #d10404;
}
.container .main .withdrawMoney .money .moneyNumber .van-cell {
  font-size: 50px;
  padding: 0!important;
}
.container .main .withdrawMoney .information{
  padding-bottom: 30px;
}
.container .main .withdrawMoney .information .description {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 0;
}
.container .main .withdrawMoney span{
  padding: 10px 0;
}
.container .main .withdrawMoney .information .balance .number{
  color: #d10404;
}
.withdraw_btn {
  margin: 150px 30px 0;
  height: 80px;
  line-height: 1.22667rem;
  border-radius: 50px;
  color: #fff;
  font-size: 30px;
  font-weight: bolder;
  border: none;
  /* background: linear-gradient(90deg,#9e9ce7,#500cfd); */
  background: #0777df;
}
</style>
