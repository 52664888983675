<template>
	<div class="container page">
		<van-nav-bar :title="$t('任务记录')" class="nav-bar">
			<template #left>
				<van-icon name="arrow-left" color="#fff" @click="back()" />
			</template>
		</van-nav-bar>
		<div class="main">
			<van-pull-refresh v-model="isLoading" @refresh="onRefresh" :pulling-text="$t('下拉即可刷新')+'...'"
				:loosing-text="$t('释放即可刷新')+'...'" :loading-text="$t('加载中...')">
				<van-list
					v-model="loadingPage"
					:finished="finished"
					:finished-text="$t('没有更多了')"
					:loading-text="$t('加载中...')"
					:immediate-check="false"
					@load="onListLoad"
				>
				
					<van-empty v-if="noDataShow" :description="$t('数据为空')" />
					<div v-else class="item_list" v-for="(v,key) in list" :key="key">
						<div class="lottery_info">
							<van-image class="cover" :src="v.ico">
								<template v-slot:loading>
									<van-loading type="spinner" />
								</template>
							</van-image>
							<span class="period-number">{{v.expect}}</span>
							<span class="period-number">{{listName(v.name)}}</span>
						</div>
						<div class="recent">
							<div class="kuaisan-ball left f f-lv-s">
								<!-- <van-image class="res-img" :src=" v.status === 0 ? 'img/lottery/open_num.gif' : 'img/lottery/shaizi/' + v.opencode[0] + '.png' " >
					<template v-slot:loading>
					<van-loading type="spinner"/>
					</template>
				</van-image>
				<van-image class="res-img" :src=" v.status === 0 ? 'img/lottery/open_num.gif' : 'img/lottery/shaizi/' + v.opencode[1] + '.png' " >
					<template v-slot:loading>
					<van-loading type="spinner"/>
					</template>
				</van-image>
				<van-image class="res-img" :src=" v.status === 0 ? 'img/lottery/open_num.gif' : 'img/lottery/shaizi/' + v.opencode[2] + '.png' " >
					<template v-slot:loading>
					<van-loading type="spinner"/>
					</template>
				</van-image> -->
								<!-- <span class="res-des middle">{{v.status === 0 ? 0 : v.opencode[0] + v.opencode[1] + v.opencode[2]}}</span> -->

								<!-- <div>
						<span class="res-des middle">{{v.status === 0 ? 0 : (v.opencode[0] + v.opencode[1] + v.opencode[2]) >= 11 && (v.opencode[0] + v.opencode[1] + v.opencode[2]) &lt;= 18 ? $t("大") : $t("小")}}</span>
						<span class="res-des middle">{{v.status === 0 ? 0 : (v.opencode[0] + v.opencode[1] + v.opencode[2]) % 2 === 0 ? $t("双") : $t("单")}}</span>
					</div> -->


								<!-- <div class="recent-profit">
						<span class="recent-profit" v-if="(v.opencode[0] + v.opencode[1] + v.opencode[2]) == 8">THB</span>
						<span class="recent-profit" v-else-if="(v.opencode[0] + v.opencode[1] + v.opencode[2]) == 7">JPY</span>
						<span class="recent-profit" v-else-if="(v.opencode[0] + v.opencode[1] + v.opencode[2]) == 6">EUR</span>
						<span class="recent-profit" v-else-if="(v.opencode[0] + v.opencode[1] + v.opencode[2]) == 5">USD</span>
						<span class="recent-profit" v-else-if="(v.opencode[0] + v.opencode[1] + v.opencode[2]) == 4">AUD</span>
						<span class="recent-profit" v-else-if="(v.opencode[0] + v.opencode[1] + v.opencode[2]) == 3">CAD</span>
						<span class="recent-profit" v-else-if="(v.opencode[0] + v.opencode[1] + v.opencode[2]) >= 11">
							{{(v.opencode[0] + v.opencode[1] + v.opencode[2]) >= 11 ? 'INR' : 'KRW'}}
						</span>
						<span class="recent-profit" v-else>
							{{(v.opencode[0] + v.opencode[1] + v.opencode[2]) % 2 === 0 ? 'GBP' : 'SGD'}}
						</span>
					</div> -->
								<!--<div class="recent-profit">{{$t('利润')}}: </div>
					<div class="recent-profit">{{$t('亏损')}}: </div> -->

							</div>
						</div>
						<div class="f f-v-c" v-for="(item,idx) in v.data" :key="idx">
							<div class="typeName">{{listTitlle(item.type)}}: <span class="typeName-text">{{item.money}}</span></div>
							<div v-if="item.is_win == 1">
								<span>{{ $t("利润") }}：</span>
								<strong style="color: #07c160;">{{item.profit}}</strong>
							</div>
							<div v-if="item.is_win == 2">
								<span>{{ $t('亏损') }}：</span>
								<strong style="color: #ff253f;">{{item.money}}</strong>
							</div>
						</div>
						<div class="time f f-lv-s">
							<span>{{ $t("下单时间") }}：{{v.create_time}}</span>
							<div>
								<span>{{ $t("下注金额") }}：</span>
								<strong style="color: #0777df;">{{v.money}}</strong>
							</div>
						</div>
						<div class="time">
							<span>{{ $t("结算时间") }}：{{v.update_time}}</span>
						</div>
					</div>
				</van-list>
			</van-pull-refresh>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				isLoading: false,
				noDataShow: false,
				list: [],
				typeTitleList:[
					{type:'大',title:'INR'},
					{type:'小',title:'KRW'},
					{type:'单',title:'SGD'},
					{type:'双',title:'GBP'},
					{type:'3',title:'CAD'},
					{type:'4',title:'AUD'},
					{type:'5',title:'USD'},
					{type:'6',title:'EUR'},
					{type:'7',title:'JPY'},
					{type:'8',title:'THB'}
				],
				pageNum: 1,
				loadingPage: true,
      			finished: false,
			};
		},
		created() {
			if (!localStorage.getItem('token')) {
				this.$router.push({
					path: '/Login'
				})
			} else {
				this.getUserGameList();
			}
		},
		methods: {
			back() {
				return window.history.back();
			},
			onRefresh() {
				this.pageNum = 1
				this.finished = false
				this.loadingPage = true;
				this.getUserGameList('refresh')
			},
			onListLoad() {
				this.pageNum++
				this.getUserGameList()
			},
			getUserGameList(type) {
				this.$http({
					method: 'get',
					url: 'user_get_game_list',
					data: {
						page: this.pageNum
					}
				}).then(res => {
					if (res.code === 200) {
						if(type == 'refresh'){
							this.list = res.data || [];
							this.$toast(this.$t('刷新成功'));
						}else{
							this.list = this.list.concat(res.data || []);
						}
						this.loadingPage = false;
						this.isLoading = false;
						this.noDataShow = this.list.length == 0 ? true : false;
						this.finished = res.data.length == 0 ? true : false;
					} else if (res.code === 401) {
						this.$toast(res.msg);
						this.isLoading = false;
						this.noDataShow = true;
						this.finished = true;
						this.loadingPage = false;
					}
				})
			},
			listTitlle(type) {
				let title = name
				this.typeTitleList.filter(item=>{
					if(item.type == type){
						title = item.title
					}
				})
				return title
			},
			listName(name) {
				let title = name
				/* if(name == 'การลงทุนระดับเบื้องต้น'){
					title = 'ห้องระดับต้น'
				}else if(name == 'การลงทุนระดับกลาง'){
					title = '้ห้องระดับกลาง'
				}else if(name == 'การลงทุนระดับวีไอพี'){
					title = 'ห้องระดับVIP'
				}else if(name == 'การลงทุนระดับซุปเปอร์วีไอพี'){
					title = 'ห้องระดับSuperVIP'
				} */
				return title
			}

		}
		
	};
</script>

<style lang='less' scoped>
	@import "../../assets/css/base.css";

	::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
		color: #000000;
		font-size: 35px;
	}

	::v-deep .van-loading__text {
		color: #000000;
		font-size: 28px;
	}

	::v-deep .van-list__finished-text{
		font-size: 28px;
		height: 100px;
		line-height: 100px;
	}
	::v-deep .van-list__loading {
		height: 100px;
		line-height: 100px;
	}

	.container .main {
		position: relative;
		overflow: auto;
		background-color: #f2f2f5;
		height: 100%;
		padding: 0 10px;
	}

	.item_list {
		padding: 15px 15px;
		margin: 30px 10px;
		background: #fff;
		border-radius: 10px;
		line-height: 60px;
	}

	.item_list .topInfo span {
		flex: 1;
		font-size: 27px;
		color: #000;
		/* color: #ff253f; */
	}

	.item_list .time span {
		flex: 1;
		font-size: 25px;
		font-weight: 500;
		color: #000;
	}

	.item_list .topInfo span:last-child {
		/* float: right; */
	}

	.item_list .desc span {
		font-size: 25px;
		font-weight: 700;
		color: #9b9b9b;
	}

	.item_list .cover {
		width: 60px;
		height: 60px;
		-o-object-fit: cover;
		object-fit: cover;
	}

	.item_list .period-number {
		margin-left: 50px;
		margin-right: 10px;
		height: 50px;
		line-height: 60px;
		font-size: 27px;
		font-weight: 700;
		color: #000;
	}

	.item_list .lottery_info {
		display: flex;
	}

	.recent {
		display: flex;
		align-items: center;
	}

	.kuaisan-ball .left {
		justify-content: flex-start;
	}

	.kuaisan-ball {
		flex: 1;
		display: flex;
		align-items: center;
	}

	.kuaisan-ball .res-img {
		width: 70px;
		height: 70px;
		margin-right: 30px;
	}

	.kuaisan-ball .res-des {
		font-weight: 700;
		text-align: center;
		color: #000;
	}

	.kuaisan-ball .res-des.middle {
		font-size: 27px;
	}

	.recent-profit {
		font-size: 27px;
	}

	.typeName {
		width: 30%;
	}
	.typeName-text{
		color: #777;
	}
</style>