<template>
	<div id="app">
		<router-view></router-view>
		<Footer />
	</div>
</template>

<script>
	import Footer from './common/Footer'
	export default {
		name: 'app',
		components: {
			Footer
		},
		data() {
			return {
				status: 0
			};
		},
		methods: {
			getBaseInfo() {
				this.$http({
					method: 'get',
					url: 'base_info'
				}).then(res => {
					if (!localStorage.getItem('token')) {
						this.$router.push({
							path: '/Login'
						})
					}
					this.$store.commit('setBaseInfoValue', res.data);
				})
			}

		},
		created() {
			this.getBaseInfo();
		}
	}
</script>

<style>
	body .van-toast {
		font-size: 38px;
		padding: 30px;
		line-height: 50px;
		width: 230px;
	}

	body .van-toast .van-toast__icon {
		font-size: 50px;
	}
	
	* {
		font-size: 27px;
	}

	*,
	:after,
	:before {
		box-sizing: border-box;
	}

	.van-picker-column {
		font-size: 5.333vw !important;
	}

	.f {
		display: flex;
		flex-direction: row;
	}

	.fre {
		flex-direction: row-reverse;
	}

	.fw {
		flex-wrap: wrap;
	}

	.fw-v-s {
		flex-direction: column;
		justify-content: space-between;
	}

	.fw-s {
		flex-wrap: wrap;
		justify-content: space-between;
	}

	.fw-lv-c {
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.fw-v-c {
		flex-direction: column;
		align-items: center;
	}

	.fw-l-c {
		flex-direction: column;
		justify-content: center;
	}

	.f-lv-c {
		align-items: center;
		justify-content: center;
	}

	.f-v-c {
		align-items: center;
	}

	.f-v-ce {
		align-items: center;
		justify-content: flex-end;
	}

	.f-l-c {
		justify-content: center;
	}

	.f-ls-s {
		align-items: flex-start;
		justify-content: space-between;
	}

	.f-lv-s {
		align-items: center;
		justify-content: space-between;
	}

	.f-lv-se {
		align-items: flex-end;
		justify-content: space-between;
	}

	.f-lv-ce {
		align-items: flex-end;
		justify-content: center;
	}

	.f-lv-sa {
		align-items: center;
		justify-content: space-around;
	}

	.f-v-e {
		align-items: flex-end;
	}

	.f-d {
		flex-direction: column;
	}

	/*字体单行省略*/
	.text_one {
		display: block;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	/*字体两行省略*/
	.text_two {
		display: -webkit-box;
		word-break: break-all;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		overflow: hidden;
		text-overflow: ellipsis;
	}
</style>