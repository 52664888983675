<template>
	<div>
		<!-- <img class="bg-img" src="img/login/login-bg.png"> -->
		<div class="wrapper">
			<div class="login">
				<van-nav-bar>
					<template #left>
						<van-icon name="arrow-left" color="#333" @click="back()" />
						<div class="login-navTitle">CitiGroup</div>
					</template>
					<template #right>
						<!-- <div class="language" @click="tolg()"><img  :src="'img/'+lg+'.png'"></div> -->
					</template>
				</van-nav-bar>
				<div class="login-head f f-lv-c">
					<!-- <img src="/img/login/tzhb-logo.jpg" class="login-head-logo" /> -->
				</div>
				<div class="wrapper">
					<div class="wrapper-title">{{$t('注册账户')}}</div>
					<div class='logo'>
						<div class="mobilelogo f f-lv-c" @click="sectionChange(0) " :class="current==0?'keyu':''">{{$t('手机注册')}}</div>
						<div class="mobilelogo f f-lv-c" @click="sectionChange(1) " :class="current==1?'keyu':''">{{$t('邮箱注册')}}</div>
					</div>
					<div class="login-item f f-v-c">
						<img class="login-item-icon" src="/img/login/logo-user.png" alt="" />
						<van-field v-model="name" class="login-item-input" :placeholder="$t('请填写姓名')" />
					</div>
					<div class="login-item f f-v-c">
						<img class="login-item-icon" src="/img/login/logo-user.png" alt="" />
						<van-field v-model="username" class="login-item-input" :placeholder="$t('请填写手机号码或邮箱')" />
					</div>
					<div class="login-item f f-v-c">
						<img class="login-item-icon" src="/img/login/logo-pwd.png" alt="" />
						<van-field v-model="password" :type="passwordType" class="login-item-input"
							:placeholder="$t('请输入密码')">
							<template slot="right-icon">
								<van-icon :name=" passwordType === 'password' ? 'closed-eye':'eye-o'"
									@click="switchPasswordType" />
							</template>
						</van-field>
					</div>
					<div class="login-item f f-v-c">
						<img class="login-item-icon" src="/img/login/logo-npwd.png" alt="" />
						<van-field v-model="newPwd" :type="passwordType" class="login-item-input"
							:placeholder="$t('请再次输入密码')">
							<template slot="right-icon">
								<van-icon :name=" passwordType === 'password' ? 'closed-eye':'eye-o'"
									@click="switchPasswordType" />
							</template>
						</van-field>
					</div>
					<div class="login-sub f">
						<van-button class="login-submitBtn" type="primary" size="normal"
							@click="doLogin()">{{ $t("确定注册") }}</van-button>
					</div>
					<div class="login-zc f f-lv-c" @click="toLogin()">
						<div class="login-zc-myzh">{{$t('现有帐户')}}?</div>
						<div class="login-zc-zczh">{{$t('请登录')}}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		model: {
			prop: 'inputValue',
			event: 'input'
		},
		props: {
			/**
			 * 当前输入的值
			 */
			inputValue: {
				type: String,
				default: ''
			}
		},
		data() {
			return {
				name:'',
				username: '',
				password: this.inputValue,
				passwordType: 'password',
				newPwd: '',
				lg: this.$i18n.locale,
				logoInfo: {},
				current: 0
			};
		},
		mounted() {

		},
		methods: {
			switchPasswordType() {
				this.passwordType = this.passwordType === 'password' ? 'text' : 'password'
			},
			back() {
				return window.history.back();
			},
			toLogin() {
				this.$router.go(-1)
			},
			tolg() {
				this.$router.push("Language")
			},
			doLogin() {
				if (!this.name) {
					this.$toast(this.$t('请填写姓名'));
					return false;
				}
				if (!this.username) {
					this.$toast(this.$t(this.current == 0 ?'请填写您的手机号码' : '请填写您的邮箱'));
					return false;
				}
				if(this.current == 0){
					const regex = /^\d{4,}$/;
					if(!regex.test(this.username)){
						this.$toast(this.$t('请输入正确的手机'));
						return false
					}
				}else{
					const email = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
					if(!email.test(this.username)){
						this.$toast(this.$t('请输入正确的邮箱'));
						return false
					}
				}
				if (!this.password) {
					this.$toast(this.$t("请输入密码"));
					return false;
				}
				if (!this.newPwd) {
					this.$toast(this.$t("请再次输入密码"));
					return false;
				}
				if (this.password != this.newPwd) {
					this.$toast(this.$t("两次密码输入不一致"));
					return false;
				}
				this.$http({
					method: 'post',
					data: {
						name: this.name,
						username: this.username,
						password: this.password,
						// code: this.code
					},
					url: 'member_register'
				}).then(res => {
					if (res.code === 200) {
						this.$toast.success(res.msg);
						localStorage.setItem('token', res.data)
						this.$router.push("Mine")
					} else {
						this.$toast.fail(res.msg);
					}
				})
			},
			sectionChange(index){
				this.current = index
				this.username = ''
				this.password = ''
				this.newPwd = ''
			}
		},
		created() {
			if (localStorage.getItem('token')) {
				return window.history.back();
			}
		}
	};
</script>

<style lang='less' scoped>
	@import "../../assets/css/base.css";

	::v-deep .nav-bar {
		background-color: transparent !important;
	}

	.language {
		position: absolute;
		top: 0.533vw;
		right: 0;
		height: 10.667vw;
	}

	.language img {
		height: 100%;
	}

	.login {
		height: 100%;
		justify-content: center;
	}

	.login-navTitle {
		font-size: 40px;
		font-weight: bold;
		color: #333;
		margin-left: 20px;
	}

	.login-head {
		width: 100%;
		height: 410px;
		background-image: url('/img/home/tzhb-tp1.jpg');
		background-size: 100% 100%;

		.login-head-logo {
			width: 200px;
			height: 200px;
			border-radius: 50%;
		}
	}

	.wrapper-title {
		margin-top: 100px;
		font-size: 38px;
		color: #000;
		padding: 0 40px;
	}

	.bg-container .bg-wrapper .login .nav-bar {
		background: 0 0
	}

	.login .wrapper {
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
	}

	::v-deep .van-field__right-icon .van-icon {
		font-size: 40px;
	}

	::v-deep .van-icon {
		font-size: 50px;
	}

	::v-deep .van-field__control {
		font-size: 28px;
		color: #000;
	}

	::v-deep .van-button__text {
		font-size: 28px;
	}


	.login-logo {
		margin-top: 20px;
		margin-bottom: 50px;
		min-height: 250px;

		img {
			width: 250px;
			height: 250px;
		}
	}

	.login-item {
		height: 80px;
		background-color: #fff;
		margin: 40px 40px 0 40px;
		box-sizing: border-box;
		border-bottom: 1px solid #ddd;

		.login-item-icon {
			width: 40px;
			height: 40px;
		}

		.login-item-input {
			font-size: 32px;
			color: #000;
		}
	}

	.login-sub {
		margin-top: 50px;
	}

	.login-submitBtn {
		flex: 1;
		margin: 0 40px;
		height: 70px;
		border-radius: 10px;
		color: #fff;
		background: #012169;
		font-size: 30px;
		border: none;
	}

	.login-zcBtn {
		flex: 1;
		margin: 0 30px;
		height: 90px;
		border-radius: 50px;
		color: #ff5500;
		background: transparent;
		font-size: 30px;
		border: 4px solid #ff5500;
		margin-top: -20px;
	}

	.login-zc {
		margin-top: 20px;

		.login-zc-myzh {
			font-size: 24px;
			color: #aaa;
		}

		.login-zc-zczh {
			font-size: 24px;
			color: #e31837;
			margin-left: 10px;
		}
	}
	.logo {
		margin-top: 50px;
		margin-left: 40px;
		display: flex;
	}
	
	.mobilelogo {
		font-style: normal;
		font-weight: 400;
		font-size: 26px;
		color: #333;
		height: 70px;
		text-align: center;
		min-width: 170px;
		padding: 0 40px;
		background-color: #eee;
		margin-right: 30px;
		border-radius: 10px;
	}
	
	.keyu {
		background: #0777df;
		color: #fff;
	}
	.login-item-tips{
		position: relative;
		top: 15px;
		margin-left: 97px;
		font-size: 23px;
		color: #ccc;
	}
</style>