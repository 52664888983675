<template>
	<div class="container page">
		<van-nav-bar :title="pageTitle" class="nav-bar">
			<template #left>
				<van-icon name="arrow-left" color="#333" @click="back()" />
			</template>
			<!--      <template #right>-->
			<!--        <div class="right">切换任务</div>-->
			<!--      </template>-->
		</van-nav-bar>
		<div class="betting-topBox">
			<div class="betting-top f">
				<div class="betting-top-item f fw-lv-c">
					<div class="betting-top-item-title">{{this.lottery.next_expect}}</div>
					<div class="betting-top-item-num">
						<van-count-down :time="time" class="f f-v-c top_box_buy_time"  @finish="check()">
						  <template v-slot="timeData">
							<div class="top_box_buy_block">{{ timeData.hours < 10 ? '0' + timeData.hours : timeData.hours }}</div>
							<div class="top_box_buy_colon">:</div>
							<div class="top_box_buy_block">{{ timeData.minutes < 10 ? '0' + timeData.minutes : timeData.minutes }}</div>
							<div class="top_box_buy_colon">:</div>
							<div class="top_box_buy_block">{{ timeData.seconds < 10 ? '0' + timeData.seconds : timeData.seconds }}</div>
						  </template>
						</van-count-down>
					</div>
				</div>
				<div class="betting-top-item">
					<div class="betting-top-item-title">{{$t('总资产')}}</div>
					<div class="betting-top-item-num">{{this.userInfo.money || 0}}</div>
				</div>
			</div>
		</div>
		<div class="betting-phase f f-lv-s" @click="active ? active = false : active = true" >
			<div class="f f-v-c">
				<span class="betting-phase-tips">{{$t('轮')}}</span>
				<span class="betting-phase-num">{{this.lottery.now_expect}}</span>
				<div class="f f-v-c betting-phase-sum" v-if="this.lottery.opencode">
					<div v-for="(item,index) in this.lottery.opencode" :key="index">
						<span>{{item}}</span>
						<span v-if="index < 2">-</span>
					</div>
				</div>
				<!-- <span class="betting-phase-text">(KRW SGD)</span> -->
				<!-- <span class="betting-phase-text">{{this.size}}</span>
				<span class="betting-phase-text">{{this.double}}</span> -->
				<div class="betting-phase-text" v-if="this.lottery.opencode && this.lottery.opencode.length > 0">
					<span class="res-des">(</span>
					<span class="res-des">
						{{(this.lottery.opencode[0] + this.lottery.opencode[1] + this.lottery.opencode[2]) >= 11 ? 'INR' : 'KRW'}}
					</span>
					<span class="res-des">
						{{(this.lottery.opencode[0] + this.lottery.opencode[1] + this.lottery.opencode[2]) % 2 === 0 ? 'GBP' : 'SGD'}}
					</span>
					<span class="res-des">)</span>
				</div>
			</div>
			<div>
				<van-icon name="arrow-down" class="betting-phase-arrow" :class="{ up: active,down:!active }" />
			</div>
		</div>
		<div class="betting-phase-zw"></div>
		
		<div class="wrapper" style="overflow-y: auto;">
			<!-- 图表 -->
			<div class="chartBox">
				<kChart v-if="loadChart"></kChart>
			</div>
			<div class="options-bar">
				<div class="game">
					<div class="game-title f f-v-c">{{$t('请选择投资方向')}}</div>
					<div class="tips f f-lv-s">
						<p class="game-odds">{{$t('利润和价值')}}</p>
						<div class="play-tip">
							<!--                  <span class="span-text" @click="playgame = true">玩法提示</span>-->
							<span class="game-odds" @click="$router.push({path:'/GameRecord'});">{{ $t("下注记录") }}</span>
							<van-popup class="mask" get-container="body" v-model="playgame">
								<div class="play-type-tip">
									<div class="title">{{ $t("玩法规则") }}</div>
									<div class="wrapper">
										<div class="item">
											<van-icon name="info-o" />
											<div class="content">
												<p class="content-title">{{ $t("玩法提示") }}</p>
												<p class="content-detail">{{ $t("从可选和值形态里面选择号码进行下注") }}</p>
											</div>
										</div>
										<div class="item">
											<van-icon name="comment-o" />
											<div class="content">
												<p class="content-title">{{ $t("中奖说明") }}</p>
												<p class="content-detail">{{ $t("三个开奖号码总和值11~18 为大;总和值3~ 10为小") }}</p>
											</div>
										</div>
										<div class="item">
											<van-icon name="description" />
											<div class="content">
												<p class="content-title">{{ $t("投注范例") }}</p>
												<p class="content-detail">{{ $t("投注方案：小 开奖号码：123,即中小") }}</p>
											</div>
										</div>
									</div>
								</div>
							</van-popup>
						</div>
					</div>
					<div class="sumValueTwoSides">
						<div class="rectangle large f fw-lv-c" :class="{rectangleActive:choose[v.type]}"
							v-for="(v,key) in lottery_peilv_list" :key="key" @click="choosePlay(v.type,v.name);">
							<div class="rectangle-name">{{v.name}}</div>
							<div class="rectangle-num">{{v.proportion}}</div>
						</div>
					</div>
					<div class="rectangle-zw"></div>
				</div>
			</div>
			<div class="bottom-bar">
				<div class="bar">
					<div class="left">
						<div class="item" @click="shopping ? shopping = false : shopping = true ">
							<!-- <van-icon name="cart-o" class="jixuanico" /> -->
							<!-- <span class="text">{{ $t("任务单") }}</span> -->
							<div class="jixuanImg">
								<img src="/img/lottery/tzhb-xz.jpg" />
							</div>
						</div>
						<div class="line"></div>
					</div>
					<div class="mid">
						<span class="text" style='font-size: 3vw;'>{{ $t("可用余额") }}</span>
						<span class="text num">{{ this.userInfo.money }}</span>
						<span class="text">{{ $t("元") }}</span>
					</div>
					<div class="right" @click="jiesuan()">
						{{ $t("提交") }}
					</div>
				</div>
				<div class="wrapper" :class="{active:shopping}">
					<div class="item">
						<span class="label">{{ $t("当前选号") }}：</span>
						<div class="bet-number">{{ this.shopchoose}}</div>
						<van-icon name="arrow-down" :class="{ up: !shopping,down:shopping }"
							@click="shopping ? shopping = false : shopping = true" />
					</div>
					<div class="item">
						<span class="label">{{ $t("每注金额") }}：</span>
						<div class="amount-wrapper">
							<van-field v-model="money" type="digit" :placeholder="$t('请输入金额')" />
							<!-- <span class="label">{{ $t("元") }}</span> -->
						</div>
					</div>
					<div class="item">
						<div class="part">
							<span>{{ $t("总共") }}</span>
							<span class="number">{{this.formData.length}}</span>
							<span>{{ $t("注") }}</span>
						</div>
						<div class="part">
							<span>{{ $t("合计") }}</span>
							<span class="number">{{this.formData.length * this.money}}</span>
							<span>{{ $t("元") }}</span>
						</div>

					</div>
				</div>
			</div>
			<van-popup v-model="jiesuanpage" get-container="body">
				<div class="confirm-order-modal">
					<div class="head van-hairline--bottom">
						<p class="text">{{ $t("任务单") }}</p>
					</div>
					<div class="list">
						<div v-for="(v,key) in formData" :key="key" class="lise-item van-hairline--bottom">
							<div class="main">
								<p class="bet-name">{{ v.name }}</p>
								<p class="detail-text">1{{ $t("注") }}X{{ money }}{{ $t("元") }}={{ money }}{{ $t("元") }}
								</p>
							</div>
							<van-icon @click="clearChooes(v.type)" name="close" />
						</div>
					</div>
					<div class="sub-bar">
						<van-button class="item cancel-btn" type="default"
							@click="allClear()">{{ $t("清空订单") }}</van-button>
						<van-button class="item sub-btn" type="default" @click="doSub">{{ $t("确认提交") }}</van-button>
					</div>
				</div>
			</van-popup>
			<van-popup v-model="active" position="top" style="width: 70%;position: fixed;width: 100%;top: 25%;height: 75%;">
				<van-pull-refresh v-model="isLoading" @refresh="onRefresh" :pulling-text="$t('下拉即可刷新')+'...'" :loosing-text="$t('释放即可刷新')+'...'" :loading-text="$t('加载中...')">
					<div class="wrapper">
						<!-- <div class="item">
							<div class="left Issue">{{ $t("期号") }}</div>
							<div class="right Issue">{{ $t("开奖号码") }}</div>
						</div> -->
						<div class="item" v-for="(v,key) in lottery_list" :key="key">
							<div class="left item-Issue">
								<span>{{v.expect}}</span>
							</div>
							<div class="right">
								<div class="kuaisan-ball left">
									<!-- <van-image class="res-img" :src="'img/lottery/shaizi/' + v.opencode[0] + '.png'">
                            <template v-slot:loading>
                              <van-loading type="spinner"/>
                            </template>
                          </van-image>
                          <van-image class="res-img" :src="'img/lottery/shaizi/' + v.opencode[1] + '.png'">
                            <template v-slot:loading>
                              <van-loading type="spinner"/>
                            </template>
                          </van-image>
                          <van-image class="res-img" :src="'img/lottery/shaizi/' + v.opencode[2] + '.png'">
                            <template v-slot:loading>
                              <van-loading type="spinner"/>
                            </template>
                          </van-image> -->
									<!-- <span class="res-des middle">{{v.opencode[0] + v.opencode[1] + v.opencode[2]}}</span> -->
									<div class="f f-v-c item-Issue-sum" v-if="v.opencode">
										<div v-for="(item,index) in v.opencode" :key="index">
											<span>{{item}}</span>
											<span v-if="index < 2">-</span>
										</div>
									</div>
									<!-- <span class="res-des middle"
										style='width: 60%;'>{{(v.opencode[0] + v.opencode[1] + v.opencode[2]) >= 11 && (v.opencode[0] + v.opencode[1] + v.opencode[2]) &lt;= 18 ? $t("大") : $t("小")}}</span>
									<span
										class="res-des middle">{{(v.opencode[0] + v.opencode[1] + v.opencode[2]) % 2 === 0 ? $t("双") : $t("单")}}</span> -->
									
									<!-- <span class="res-des" style='width: 80%;' v-if="(v.opencode[0] + v.opencode[1] + v.opencode[2]) == 8">(THB)</span>
									<span class="res-des" style='width: 80%;' v-else-if="(v.opencode[0] + v.opencode[1] + v.opencode[2]) == 7">(JPY)</span>
									<span class="res-des" style='width: 80%;' v-else-if="(v.opencode[0] + v.opencode[1] + v.opencode[2]) == 6">(EUR)</span>
									<span class="res-des" style='width: 80%;' v-else-if="(v.opencode[0] + v.opencode[1] + v.opencode[2]) == 5">(USD)</span>
									<span class="res-des" style='width: 80%;' v-else-if="(v.opencode[0] + v.opencode[1] + v.opencode[2]) == 4">(AUD)</span>
									<span class="res-des" style='width: 80%;' v-else-if="(v.opencode[0] + v.opencode[1] + v.opencode[2]) == 3">(CAD)</span> -->
									
									<div style='width: 80%;'>
										<span class="res-des">(</span>
										<span class="res-des">
											{{(v.opencode[0] + v.opencode[1] + v.opencode[2]) >= 11 ? 'INR' : 'KRW'}}
										</span>
										<span class="res-des">
											{{(v.opencode[0] + v.opencode[1] + v.opencode[2]) % 2 === 0 ? 'GBP' : 'SGD'}}
										</span>
										<span class="res-des">)</span>
									</div>
									
									<!-- <span class="res-des" style='width: 80%;'>(KRW SGD)</span> -->
								</div>
							</div>
						</div>
					</div>
				</van-pull-refresh>
			</van-popup>
		</div>
	</div>
</template>

<script>
	var time;
	var count = 0;
	import kChart from './chart.vue'
	export default {
		components:{
			kChart
		},
		data() {
			return {
				jiesuanpage: false,
				choose: {
					"大": false,
					"小": false,
					"单": false,
					"双": false,
					"3": false,
					"4": false,
					"5": false,
					"6": false,
					"7": false,
					"8": false,
				},
				playgame: false,
				shopping: false,
				isLoading: false,
				play: {},
				lottery_peilv_list: {},
				lottery_list: {},
				active: false,
				userInfo: {},
				lottery: {},
				shanzi_1: "0",
				shanzi_2: "0",
				shanzi_3: "0",
				sum: 0,
				size: "",
				double: "",
				time: 0,
				shopchoose: this.$t('未选择'), //"未选择",
				gameitem: "",
				formData: [],
				money: "",
				loadChart: true,
				pageTitle: '',
				userCount: 0,
			};
		},
		created() {
			if (!localStorage.getItem('token')) {
				this.$router.push({
					path: '/Login'
				})
			} else {
				this.pageTitle = this.$route.query.title || ''
				this.getUserInfo();
				this.getLotteryInfo();
				this.getLotteryList();
			}
		},
		destroyed() {
			clearInterval(time);
		},
		methods: {
			back() {
				return window.history.back();
			},
			doSub() {
				if (this.money === "0") {
					this.$toast(this.$t('金额错误！'));
					return false;
				}
				if (this.formData.length === 0) {
					this.$toast(this.$t('请选号！'));
					return false;
				} else if (this.money === "") {
					this.$toast(this.$t('请填写金额！'));
					return false;
				} else {
					if (this.userInfo.money - (this.money * this.formData.length) < 0) {
						this.$toast(this.$t('余额不足，请联系客服充值！'));
						return false;
					} else {
						let formData = {
							item: this.gameitem,
							money: this.money,
							lid: this.lottery.id,
							mid: this.userInfo.id,
							expect: this.lottery.now_expect
						}
						this.$http({
							method: 'post',
							data: formData,
							url: 'game_place_order'
						}).then(res => {
							if (res.code === 200) {
								this.$toast(this.$t('交易成功'));
								this.allClear();
								this.getUserInfo();
							} else if (res.code === 401) {
								this.$toast(res.msg);
							}
						})
						return true;
					}
				}
			},
			allClear() {
				for (var i = 0; i < this.formData.length; i++) {
					this.choose[this.formData[i]['type']] = false;
				}
				this.formData.length = 0;
				this.money = "";
				this.shopchoose = this.$t('未选中'); //"未选中";
				this.gameitem = "";
				this.shopping = false;
				this.jiesuanpage = false;
			},
			jiesuan() {
				if (this.formData.length === 0) {
					this.$toast(this.$t('请选号！'));
					return false;
				} else if (this.money === "") {
					this.$toast(this.$t('请填写金额！'));
					return false;
				} else {
					this.jiesuanpage ? this.jiesuanpage = false : this.jiesuanpage = true;
				}

			},
			clearChooes(type) {
				for (var i = 0; i < this.formData.length; i++) {
					if (type === this.formData[i]['type']) {
						this.formData.splice(i, 1)
						this.choose[type] = false;
					}
				}
				if (this.formData.length >= 1) {
					for (var j = 0; j < this.formData.length; j++) {
						if (j === 0) {
							this.shopchoose = this.formData[j]['name'];
							this.gameitem = this.formData[j]['type'];
						} else {
							this.shopchoose += "," + this.formData[j]['name'];
							this.gameitem += "," + this.formData[j]['type'];
						}
					}
				} else {
					this.shopchoose = this.$t('未选中');
					this.gameitem = "";
					this.shopping = false;
				}
				if (this.formData.length === 0) {
					this.jiesuanpage = false;
				}
			},
			choosePlay(type, name) {
				if (this.choose[type] === true) {
					this.choose[type] = false;
					for (var i = 0; i < this.formData.length; i++) {
						if (type === this.formData[i]['type']) {
							this.formData.splice(i, 1)
						}
					}
				} else if (this.choose[type] === false) {
					this.formData.push({
						'name': this.$t(name),
						'type': type
					})
					this.choose[type] = true;
				}
				if (this.formData.length === 1) {
					this.shopping = true;
				}
				if (this.formData.length >= 1) {
					for (var j = 0; j < this.formData.length; j++) {
						if (j === 0) {
							this.shopchoose = this.formData[j]['name'];
							this.gameitem = this.formData[j]['type'];
						} else {
							this.shopchoose += "," + this.formData[j]['name'];
							this.gameitem += "," + this.formData[j]['type'];
						}
					}
				} else {
					this.shopchoose = this.$t('未选中');
					this.gameitem = "";
					this.shopping = false;
				}
				console.log(this.gameitem)

			},
			check() {
				if (!localStorage.getItem('token')) {
					this.$router.push({
						path: '/Login'
					})
				} else {
					console.log()
					time = window.setInterval(() => {
						setTimeout(() => {
							this.getUserInfo();
							this.getLotteryInfo();
							this.getLotteryList();
							count++;
							if (count > 5) {
								clearInterval(time);
								count = 0;
								this.loadChart = false
								this.$nextTick(()=>{
									this.loadChart = true
									this.getUserMoney()
								})
							}
						}, 0)
					}, 300)
				}
			},
			// 定时刷新获取用户余额
			getUserMoney() {
				setTimeout(() => {
					this.userCount += 1
					this.getUserInfo();
					if(this.userCount < 5){
						this.getUserMoney()
					}else{
						this.userCount = 0	
					}
				}, 1500)
			},
			onRefresh() {
				setTimeout(() => {
					this.$toast(this.$t('刷新成功'));
					this.getLotteryList();
					this.isLoading = false;
				}, 200);
			},
			getUserInfo() {
				this.$http({
					method: 'get',
					url: 'user_info'
				}).then(res => {
					if (res.code === 200) {
						this.userInfo = res.data;
						console.log('this.userInfo===>',this.userInfo.money)
					} else if (res.code === 401) {
						this.$toast(res.msg);
					}
				})
			},
			getLotteryPeilv() {
				this.$http({
					method: 'get',
					data: {
						id: this.$route.query.id
					},
					url: 'lottery_get_peilv'
				}).then(res => {
					if (res.code === 200) {
						this.lottery_peilv_list = res.data;
					} else if (res.code === 401) {
						this.$toast(res.msg);
					}
				})
			},
			getLotteryList() {
				this.$http({
					method: 'get',
					data: {
						key: this.$route.query.key
					},
					url: 'lottery_get_one_list'
				}).then(res => {
					if (res.code === 200) {
						this.lottery_list = res.data;
						// console.log('this.lottery_list===>',this.lottery_list)
						this.getLotteryPeilv();
					} else if (res.code === 401) {
						this.$toast(res.msg);
					}
				})
			},
			getLotteryInfo() {
				this.$http({
					method: 'get',
					data: {
						key: this.$route.query.key
					},
					url: 'lottery_get_info'
				}).then(res => {
					if (res.code === 200) {
						if (parseFloat(this.userInfo.money) < parseFloat(res.data.condition)) {
							this.$toast(this.$t('请联系管理员领取该任务'));
							this.$router.push({
								path: '/Home'
							})
							return false;
						}
						this.lottery = res.data;
						// console.log('this.lottery==========>',this.lottery)
						this.time = res.data.second * 1000;

						if (this.time / 1000 === 59) {
							this.$toast(this.$t('开奖成功，期号') + "：" + this.lottery.now_expect);
						}
						this.shanzi_1 = "img/lottery/shaizi/" + res.data?.opencode[0] + ".png";
						this.shanzi_2 = "img/lottery/shaizi/" + res.data?.opencode[1] + ".png";
						this.shanzi_3 = "img/lottery/shaizi/" + res.data?.opencode[2] + ".png";
						this.sum = res.data.opencode[0] + res.data.opencode[1] + res.data.opencode[2];
						if (this.sum >= 11 && this.sum <= 18) {
							this.size = this.$t('大'); //"大";
						} else if (this.sum >= 3 && this.sum <= 10) {
							this.size = this.$t('小'); //"小";
						}
						if (this.sum % 2 === 0) {
							this.double = this.$t('双'); //"双";
						} else {
							this.double = this.$t('单'); //"单";
						}
					} else if (res.code === 401) {
						this.$toast(res.msg);
					}
				})

			}
		}
	};
</script>

<style lang='less' scoped>
	@import "../../assets/css/base.css";
	
	.page{
		background-color: #fff;
	}
	.nav-bar{
	    background: #fff;
	    height: 100px;
	}
	::v-deep .van-nav-bar__content {
	    height: 100px;
	}
	::v-deep .van-nav-bar__title {
	    max-width: 60%;
	    margin: 0 auto;
	    color: #333;
	    font-size: 32px;
	}

	.record {
		padding-left: 20px;
		padding-right: 20px;
		background-color: #fff;
		box-shadow: 0 2px 2px 0 #cacaca;
		z-index: 1;
	}

	.record .period {
		display: flex;
		align-items: center;
		padding: 20px 0;
	}

	.record .period .cover {
		width: 60px;
		height: 60px;
		-o-object-fit: cover;
		object-fit: cover;
	}

	.record .period .period-number {
		flex: 1;
		margin-left: 20px;
		margin-right: 10px;
		height: 50px;
		line-height: 50px;
		font-size: 35px;
		font-weight: 700;
		color: #000;
	}

	.van-count-down {
		color: #ff253f;
		font-size: 45px;
		margin-top: 10px;
		float: right;
	}

	.linear-gradient {
		width: 100%;
		height: 2px;
	}

	.record .recent {
		display: flex;
		align-items: center;
		height: 110px;
	}

	.kuaisan-ball .left {
		justify-content: flex-start;
	}

	.kuaisan-ball {
		flex: 1;
		display: flex;
		align-items: center;
	}

	.kuaisan-ball .res-img {
		width: 70px;
		height: 70px;
		margin-right: 30px;
	}

	.kuaisan-ball .res-des {
		font-weight: 700;
		text-align: center;
		color: #000;
	}

	.kuaisan-ball .res-des.middle {
		width: 15%;
		font-size: 35px;
	}

	.van-icon {
		font-size: 40px;
	}

	.down {
		transition: all .5s;
	}

	.up {
		transform: rotate(180deg);
		transition: all .5s;
	}

	.wrapper {
		position: relative;
		flex: 1;
		overflow: hidden;
	}

	.options-bar {
		display: flex;
		align-items: center;
		height: calc(100% - 80px);
	}

	.options-bar .game {
		flex: 1;
		height: 100%;
		/* overflow-y: auto; */
		-webkit-overflow-scrolling: touch;
	}

	.options-bar .game .tips {
		display: flex;
		align-items: center;
		height: 100px;
		padding: 0 20px;
	}

	.options-bar .game .tips .odds {
		flex: 1;
		font-size: 35px;
		font-weight: 500;
		color: #ff253f;
	}

	.options-bar .game .tips .play-tip {
		display: flex;
		align-items: center;
		height: 100%;
	}

	::v-deep .van-icon-more-o {
		color: #ff253f;
		font-size: 50px;
	}

	.options-bar .game .tips .play-tip .span-text {
		margin-left: 10px;
		font-size: 35px;
		font-weight: bolder;
		color: #ff253f;
	}

	.linear-gradient {
		width: 100%;
		height: 2px;
	}

	.sumValueTwoSides {
		display: flex;
		padding: 30px 0;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		flex-wrap: wrap;
	}

	.rectangle {
		overflow: hidden;
	}

	.rectangle.large {
		margin: 0 0 30px 4%;
		width: 20%;
		border-radius: 10px;
		background: #f6f7f9;
		padding: 30px 0;
	}

	.rectangle .wrapper {
		position: relative;
		padding: 0 10px;
		background: #f6f7f9;
	}

	.rectangle .wrapper .content {
		position: absolute;
		display: flex;
		top: 0;
		left: 0;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
	}
	

	.rectangle.large .wrapper {
		padding-bottom: 100%;
	}

	.rectangle .wrapper .content .name-text.large {
		font-size: 5vw;
	}

	.rectangle .wrapper .content .name-text {
		color: #7d7c7c;
		font-weight: bolder;
	}

	.rectangle .wrapper .content .odd-text.large {
		font-size: 25px;
		margin-top: -30px;
	}

	.rectangle .wrapper .content .odd-text {
		text-align: center;
		color: #ff253f;
	}

	.bottom-bar {
		position: fixed;
		left: 0;
		right: 0;
		bottom: 0;
		height: 100px;
		z-index: 2;
	}

	.bottom-bar .bar .left,
	.bottom-bar .bar {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.bottom-bar .bar {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		height: 100px;
		background-color: #fff;
		box-shadow: 0 0 20px 0 #cacaca;
		z-index: 2;
	}

	.bottom-bar .bar .left,
	.bottom-bar .bar {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.bottom-bar .bar .left .item {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100px;
		font-size: 20px;
	}

	.bottom-bar .bar .left .item .text {
		font-size: 22px;
		color: #7d7c7c;
	}

	.jixuanico {
		font-size: 45px;
	}
	.jixuanImg{
		width: 65px;
		height: 65px;
		overflow: hidden;
		img{
			position: relative;
			top: -17px;
			left: -17px;
			width: 105px;
			height: 105px;
		}
	}

	.bottom-bar .bar .left .line {
		width: 2px;
		height: 50px;
		background: #dadada;
	}

	.bottom-bar .bar .mid {
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}

	.bottom-bar .bar .mid .text {
		font-size: 30px;
		font-weight: 500;
		color: #000;
	}

	.bottom-bar .bar .mid .text.num {
		margin: 0 5px;
		color: #ff253f;
	}

	.bottom-bar .bar .right {
		padding: 0 30px;
		margin: 0 30px;
		color: #fff;
		background: linear-gradient(270deg, #9e9ce7, #500cfd);
		font-size: 30px;
		font-weight: 500;
		height: 70px;
		line-height: 70px;
		border-radius: 50px;
	}

	.rectangle.active .wrapper {
		background-color: #ff253f !important;
	}

	::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
		color: #000000;
		font-size: 35px;
	}

	::v-deep .van-popup {
		position: absolute;
	}

	::v-deep .van-overlay {
		position: absolute;
		background-color: rgb(70 67 67 / 70%);
	}

	::v-deep .van-popup--top {
		top: -1px;
	}

	.wrapper .item {
		display: flex;
		flex-direction: row;
		align-items: center;
		padding: 10px 0;
	}

	.wrapper .item .left {
		width: 40%;
		font-size: 30px;
		text-align: center;
		font-weight: 500;
		color: #000;
	}

	.font-weight {
		font-weight: 700 !important;
	}

	.wrapper .item .right {
		flex: 1;
		display: flex;
		font-size: 30px;
		justify-content: center;
		overflow: hidden;
		color: #000;
	}

	.wrapper .item .kuaisan-ball .left {
		justify-content: flex-start;
	}

	.wrapper .item .kuaisan-ball {
		margin-left: 20px;
		flex: 1;
		display: flex;
		align-items: center;
	}

	.wrapper .item .kuaisan-ball .res-img {
		width: 50px;
		height: 50px;
		margin-right: 20px;
	}

	.wrapper .item .kuaisan-ball .res-des {
		font-weight: 700;
		text-align: center;
		color: #aaa;
		font-size: 22px;
	}

	.wrapper .item .kuaisan-ball .res-des.middle {
		width: 21%;
		font-size: 27px;
	}

	.play-type-tip {
		position: unset;
		margin: auto;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 650px;
		height: 700px;
		max-height: 50%;
		z-index: 10;
		border-radius: 30px;
		overflow: hidden;
		background-color: #fff;
		color: #000;
	}

	.play-type-tip .title {
		line-height: 90px;
		background: linear-gradient(90deg, #7e5678, #e6c3a1);
		text-align: center;
		color: #fff;
		font-size: 35px;
		font-weight: 500;
	}

	.mask {
		background-color: rgb(0 0 0 / 0%);
		animation-duration: 0.35s;
	}

	.play-type-tip .wrapper {
		height: calc(100% - 10px);
		background-color: transparent;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
	}

	.play-type-tip .wrapper .item {
		padding: 40px 50px;
		display: flex;
		align-items: flex-start;
	}

	.play-type-tip .wrapper .item .van-icon {
		color: #e6c3a1;
		font-size: 60px;
	}

	.play-type-tip .wrapper .item .content .content-title {
		margin-top: 22px;
		font-size: 35px;
		font-weight: 500;
		color: #000;
		line-height: 0px;
	}

	.play-type-tip .wrapper .item .content .content-detail {
		margin-top: 5px;
		font-size: 22px;
		color: #000;
		line-height: 30px;
	}

	.play-type-tip .wrapper .item .content {
		flex: 1;
		margin-left: 30px;
	}

	.rectangle.active .wrapper {
		background-color: #ff253f !important;
	}

	.rectangle.active .wrapper .name-text,
	.rectangle.active .wrapper .odd-text {
		color: #fff !important;
	}

	.bottom-bar .wrapper {
		position: absolute;
		top: 10px;
		left: 0;
		right: 0;
		padding: 20px 20px 10px 20px;
		height: 230px;
		background-color: #fff;
		z-index: 1;
		box-shadow: 0 0 10px 0 #cacaca;
		transition: transform .3s cubic-bezier(.21, 1.02, .55, 1.01);
	}

	.bottom-bar .wrapper.active {
		transform: translateY(-100%);
	}

	.bottom-bar .wrapper .item {
		position: relative;
		display: flex;
		flex-direction: row;
		align-items: center;
		height: 65px;
	}

	.bottom-bar .wrapper .item .label {
		font-size: 30px;
		line-height: 30px;
		color: #000;
	}

	.bottom-bar .wrapper .item .bet-number {
		flex: 1;
		margin: 0 16px;
		overflow: auto;
		white-space: nowrap;
		-webkit-overflow-scrolling: touch;
		color: #ff253f;
		font-size: 30px;
		font-weight: 500;
		height: 40px;
		line-height: 40px;
	}

	.bottom-bar .wrapper .item .amount-wrapper {
		flex: 1;
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.van-cell {
		font-size: 30px;
		line-height: 50px;
	}

	.bottom-bar .wrapper .item .part {
		margin-right: 20px;
	}

	.bottom-bar .wrapper .item .part span {
		font-size: 30px;
		vertical-align: center;
		color: #000;
	}

	.bottom-bar .wrapper .item .part .number {
		margin: 0 5px;
		color: #ff253f;
		font-weight: 500;
	}

	::v-deep .van-field__control {
		color: #ff253f;
		font-size: 28px;
	}

	.confirm-order-modal {
		position: unset;
		display: flex;
		flex-direction: column;
		margin: auto;
		padding: 0 20px 30px;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 610px;
		height: 680px;
		max-height: 50%;
		z-index: 10;
		background-color: #fff;
		border-radius: 30px;
	}

	.confirm-order-modal .head {
		position: relative;
		height: 80px;
	}

	.confirm-order-modal .head .text {
		padding: 0 20px;
		height: 30px;
		line-height: 10px;
		text-align: center;
		font-size: 35px;
		font-weight: 500;
		color: #7e5678;
	}

	::v-deep .confirm-order-modal .van-hairline--bottom::after {
		border-bottom-width: 2px;
	}

	.van-popup--center {
		border-radius: 30px;
	}

	.confirm-order-modal .list {
		flex: 1;
		padding: 0 10px;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
	}

	.confirm-order-modal .list .lise-item {
		display: flex;
		flex-direction: row;
		align-items: center;
		padding: 10px 0;
	}

	.confirm-order-modal .list .lise-item .main {
		flex: 1;
		overflow: hidden;
	}

	.confirm-order-modal .list .lise-item .main .bet-name {
		color: #ff253f;
		font-size: 35px;
		font-weight: 500;
		line-height: 0px;
		word-wrap: break-word;
		word-break: break-all;
	}

	.confirm-order-modal .list .lise-item .main .detail-text {
		line-height: 0px;
		font-size: 25px;
		color: #979799;
	}

	.confirm-order-modal .list .lise-item {
		color: #ff253f;
	}

	.confirm-order-modal .sub-bar {
		display: flex;
		align-items: center;
		margin-top: 30px;
		justify-content: space-around;
	}

	.confirm-order-modal .sub-bar .item {
		min-width: 40%;
		height: 80px;
		text-align: center;
		box-sizing: border-box;
		border-radius: 50px;
		font-size: 35px;
		font-weight: 500;
	}

	.confirm-order-modal .sub-bar .item.cancel-btn {
		border: 2px solid #979799;
		color: #979799;
		background-color: #fff;
	}

	.confirm-order-modal .sub-bar .item.sub-btn {
		background: linear-gradient(270deg, #e6c3a1, #7e5678);
		color: #fff;
		border: 0;
	}

	.next-number span {
		font-size: 35px;
		font-weight: 700;
		color: #000;
		float: right;
	}
	
	.betting-topBox {
		padding: 0 20px;
		box-sizing: border-box;
	
		.betting-top {
			background-color: #1d8dd9;
			padding: 40px 0;
			box-sizing: border-box;
			border-radius: 10px;
	
			.betting-top-item {
				flex: 1;
				border-right: 1px solid hsla(0, 0%, 100%, .3);
				box-sizing: border-box;
				padding: 0 20px;
				&:last-child {
					border-right: none;
					padding-left: 50px;
				}
	
				.betting-top-item-title {
					font-size: 21px;
					color: #fff;
				}
	
				.betting-top-item-num {
					font-size: 35px;
					color: #fff;
					font-weight: bold;
					margin-top: 10px;
				}
			}
		}
	}
	.top_box_buy_time {
		margin-top: 10px;
	}
	
	.top_box_buy_block {
		color: #1d8dd9;
		font-size: 20px;
		padding: 5px 10px;
		border-radius: 5px;
		background-color: #fff;
		border: 1px solid #fff;
		font-weight: bold;
	}
	
	.top_box_buy_colon {
		margin: 0 10px;
		color: #fff;
		font-size: 20px;
		font-weight: bold;
	}
	
	.betting-phase{
		height: 100px;
		box-sizing: border-box;
		padding: 0 20px;
		.betting-phase-tips{
			font-size: 27px;
			color: #aaa;
			margin-right: 20px;
		}
		.betting-phase-num{
			font-size: 27px;
			color: #e31837;
			font-weight: bold;
		}
		.betting-phase-sum{
			margin-left: 20px;
			font-weight: bold;
		}
		.betting-phase-text{
			font-size: 27px;
			color: #999;
			font-weight: bold;
			margin-left: 20px;
		}
		.betting-phase-arrow{
			font-size: 35px;
			color: rgb(170, 170, 170);
		}
	}
	.betting-phase-zw{
		height: 20px;
		background-color: #f6f7f9;
	}
	.game-title{
		height: 90px;
		font-size: 24px;
		color: #000;
		border-bottom: 1px solid #ececec;
		padding: 0 20px;
		font-weight: bold;
	}
	.game-odds{
		font-size: 24px;
		color: #aaa;
	}
	.rectangle-name{
		font-size: 22px;
		color: #aaa;
	}
	.rectangle-num{
		margin-top: 5px;
		font-size: 27px;
		color: #000;
		font-weight: bold;
	}
	.rectangleActive{
		background-color: #e31837 !important;
		.rectangle-name{
			color: #fff !important;
		}
		.rectangle-num{
			color: #fff !important;
		}
	}
	::v-deep .van-button__text{
		font-size: 27px;
	}
	.Issue{
		font-size: 27px !important;
		color: #999 !important;
		font-weight: bold !important;
	}
	.item-Issue{
		font-size: 26px !important;
		color: #999 !important;
	}
	.item-Issue-sum{
		color: #000;
		span{
			font-size: 26px;
			font-weight: bold;
		}
	}
	.chartBox{
		padding: 0 20px;
	}
	.rectangle-zw{
		height: 100px;
	}
</style>