<template>
	<div class="container page">

		<van-nav-bar class="nav-bar" :title="$t('语言选择')">
			<template #left>
				<van-icon name="arrow-left" color="#fff" @click="back()" />
			</template>



		</van-nav-bar>
		<div class="items">

			<div class="item van-hairline--bottom" @click="toggle(item.code)" v-for="(item,index) in langList" :key="index">
				<div class="flex_center">
					<!-- <div><img src="/img/vm.png"></div> -->
					<div class="info">{{item.language}}</div>
				</div>
				<div v-if="lg == item.code" class="item-xz">✓</div>
			</div>
			<!-- <div  class="item van-hairline--bottom" @click="toZh()">
					<div  class="flex_center">
						<div ><img  src="/img/zh.png"></div>
						<div  class="info">简体中文</div>
					</div>
					<div v-if="lg == 'zh'">✓</div>
				</div> -->


		</div>

	</div>
</template>

<script>
	export default {
		model: {
			prop: 'inputValue',
			event: 'input'
		},
		props: {

		},
		data() {
			return {
				lg: this.$i18n.locale,
				langList:[
					// {"code":"zh","language":"中文简体"},
					{"code":"en","language":"English"},
					{"code":"th","language":"ภาษาไทย"},
					// {"code":"vm","language":"Việt Nam"},
					{"code":"ja","language":"にほんご"},
					{"code":"de","language":"Deutsch"},
				]
			};
		},
		methods: {
			back() {
				return window.history.back();
			},
			toggle(val) {
				this.lg = val
				this.$i18n.locale = val
				localStorage.setItem('multilingual',val)
				this.$router.push("Home")
				window.location.reload();
			}
		}
	};
</script>

<style lang='less' scoped>
	@import "../../assets/css/base.css";

	.container .items {
		background-color: #fff;
		box-sizing: border-box;
	}

	.container .items .item {
		padding: 1.333vw 3.333vw;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		border-bottom-width: 1.333vw;
		border-bottom: 1px solid #ddd;
		box-sizing: border-box;
	}
	.container .items .item:last-child{
		border-bottom: none;
	}
	.item-xz{
		font-size: 30px;
	}

	.flex_center {
		display: flex;
		align-items: center;
	}

	.flex_center img {
		width: 8vw;
		height: 8vw;
		margin-right: 2.667vw;
	}

	.info {
		padding: 2.667vw;
		font-size: 27px;
	}

	.language {
		position: absolute;
		top: 0.533vw;
		right: 0;
		height: 10.667vw;
	}

	.language img {
		height: 100%;
	}

	.login {
		height: 100%;
	}

	.bg-container .bg-wrapper .login .nav-bar {
		/* background: 0 0 */
	}

	.login .wrapper {
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
	}

	.logo-container {
		margin: 0 auto;
		width: 45%;
	}

	.logo-container .logo-wrapper {
		position: relative;
		padding-bottom: 62.5%;
	}

	.logo-container .logo-wrapper .logo-img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		-o-object-fit: contain;
		object-fit: contain;
	}

	.login .wrapper .title {
		line-height: 100px;
		text-align: center;
		font-size: 45px;
		font-weight: 700;
		color: #fff;
		letter-spacing: 5px;
	}

	.login .wrapper .loginForm {
		padding: 60px;
	}

	.login .wrapper .loginForm .input {
		padding: 10px 20px;
		margin-top: 40px;
		border-radius: 50px;
		text-align: center;
		line-height: 80px;
		font-size: 30px;
		color: #4e4e4e;
	}

	::v-deep .van-field__right-icon .van-icon {
		font-size: 50px;
	}

	::v-deep .van-icon {
		font-size: 50px;
	}

	.login .wrapper .loginForm .reset-text {
		margin: 30px 15px;
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}

	.login .wrapper .loginForm .reset-text span {
		color: #fff;
		font-size: 25px;
		font-weight: 500;
		line-height: 15px;
	}

	.login .wrapper .loginForm .register-text {
		margin: 10px 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.login .wrapper .loginForm .register-text span {
		color: #fff;
		font-size: 25px;
		font-weight: 500;
		line-height: 20px;
	}

	.login .wrapper .loginForm .active {}

	.login .wrapper .loginForm .login-btn {
		margin-top: 85px;
		width: 100%;
		height: 100px;
		border-radius: 50px;
		color: #fff;
		/* background-color: #7e5678; */
		background: linear-gradient(90deg, #c24491, #775fd9);
		font-size: 30px;
		font-weight: bolder;
		border: none;
	}
</style>