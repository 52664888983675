<template>
	<div class="container page">
		<van-nav-bar :title="$t('账户明细')" class="nav-bar">
			<template #left>
				<van-icon name="arrow-left" color="#fff" @click="back()" />
			</template>
		</van-nav-bar>
		<div class="content" style="background-color: #f2f2f5;">
			<van-pull-refresh v-model="isLoading" @refresh="onRefresh" :pulling-text="$t('下拉即可刷新')+'...'"
				:loosing-text="$t('释放即可刷新')+'...'" :loading-text="$t('加载中...')">
				<van-empty v-if="list.length === 0" :description="$t('数据为空')" />
				<div v-else class="listItem" v-for="(v,key) in list" :key="key">
					<div>
						<div class="listTitle">{{listTitlle(v.type)}}</div>
						<div class="listTime">
							<div class='listTimeText'>{{v.createtime}}</div>
						</div>

					</div>
					<div style="flex: 1 1 0%;"></div>
					<div>
						<div class="listMoney" v-if="v.color === 1" style="color: rgb(7, 193, 96);">{{v.money}}</div>
						<div class="listMoney" v-else>{{v.money}}</div>
						<div class="list-status">
							<span v-if="v.accstatus == 1">{{ $t('等待中') }}</span>
							<span v-if="v.accstatus == 2" style="color: rgb(7, 193, 96);">{{ $t('成功') }}</span>
							<span v-if="v.accstatus == 3" style="color: #ff253f;">{{ $t('失败') }}</span>
						</div>
					</div>
				</div>
			</van-pull-refresh>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				isLoading: false,
				list: [],
			};
		},
		methods: {
			back() {
				return window.history.back();
			},
			onRefresh() {
				setTimeout(() => {
					this.$toast(this.$t('刷新成功'));
					this.getUserGameList()
				}, 500);
			},
			getUserGameList() {
				this.$http({
					method: 'get',
					url: 'user_money_log_list'
				}).then(res => {
					if (res.code === 200) {
						console.log(res.data)
						this.list = res.data;
						this.isLoading = false;
					} else if (res.code === 401) {
						this.$toast(res.msg);
						this.isLoading = false;
					}
				})
			},
			listTitlle(name){
				if(name == 'Admin'){
					return 'เติม'
				}else if(name == 'Withdarwal'){
					return 'การถอนเงิน'
				}else if(name == 'ความสนใจ'){
					return 'อัตราดอกเบี้ย'
				}
				return name
			}

		},
		created() {
			if (!localStorage.getItem('token')) {
				this.$router.push({
					path: '/Login'
				})
			} else {
				this.getUserGameList();
			}
		}
	};
</script>

<style lang='less' scoped>
	@import "../../assets/css/base.css";

	::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
		color: #000000;
		font-size: 35px;
	}

	::v-deep .van-loading__text {
		color: #000000;
		font-size: 35px;
	}
	
	.content{
		height: 100%;
		overflow: auto;
	}

	.container .content .listItem {
		margin-bottom: 2.667vw;
		padding: 2.667vw;
		position: relative;
		color: #000;
		background-color: #fff;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.container .content .listItem .listTitle {
		font-size: 30px;
		margin-bottom: 2.667vw;
	}

	.container .content .listItem .listTime .listTimeText {
		color: #656566;
		font-size: 24px;
	}

	.container .content .listItem .listMoney {
		color: #dc2037;
		text-align: right;
	}


	.container .main {
		position: relative;
		overflow: auto;
		background-color: #f2f2f5;
		height: 100%;
		padding: 0 10px;
	}

	.item_list {
		padding: 15px 15px;
		margin: 30px 10px;
		background: #fff;
		border-radius: 10px;
		line-height: 60px;
	}

	.item_list .topInfo span {
		flex: 1;
		font-size: 35px;
		font-weight: 700;
		color: #ff253f;
	}

	.item_list .time span {
		flex: 1;
		font-size: 25px;
		font-weight: 500;
		color: #000;
	}

	.item_list .topInfo span:last-child {
		float: right;
	}

	.item_list .desc span {
		font-size: 25px;
		font-weight: 700;
		color: #9b9b9b;
	}

	.item_list .cover {
		width: 60px;
		height: 60px;
		-o-object-fit: cover;
		object-fit: cover;
	}

	.item_list .period-number {
		margin-left: 50px;
		margin-right: 10px;
		height: 50px;
		line-height: 60px;
		font-size: 35px;
		font-weight: 700;
		color: #000;
	}

	.item_list .lottery_info {
		display: flex;
	}

	.recent {
		display: flex;
		align-items: center;
		height: 100px;
	}

	.kuaisan-ball .left {
		justify-content: flex-start;
	}

	.kuaisan-ball {
		flex: 1;
		display: flex;
		align-items: center;
	}

	.kuaisan-ball .res-img {
		width: 70px;
		height: 70px;
		margin-right: 30px;
	}

	.kuaisan-ball .res-des {
		font-weight: 700;
		text-align: center;
		color: #000;
	}

	.kuaisan-ball .res-des.middle {
		width: 15%;
		font-size: 35px;
	}
	.list-status{
		margin-top: 10px;
		font-size: 25px;
		font-weight: 700;
		color: #9b9b9b;
		text-align: right;
	}
</style>