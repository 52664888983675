<template>
	<div class="findBox">
		<div>
			<div class="lengend">
				<div class="m-bar">
					<div class="line">
						<div class="line-big"></div>
					</div>
					<div class="bar-text">{{ $t("做多") }}</div>
				</div>
				<div class="m-bar">
					<div class="line">
						<div class="line-big"></div>
					</div>
					<div class="bar-text">{{ $t("做空") }}</div>
				</div>
				<div class="m-bar">
					<div class="line">
						<div class="line-small"></div>
						<div class="line-big"></div>
					</div>
					<div class="bar-text">{{ $t("多单") }}</div>
				</div>
				<div class="m-bar">
					<div class="line">
						<div class="line-small"></div>
						<div class="line-big"></div>
					</div>
					<div class="bar-text">{{ $t("多双") }}</div>
				</div>
				<div class="m-bar">
					<div class="line">
						<div class="line-small"></div>
						<div class="line-big"></div>
					</div>
					<div class="bar-text">{{ $t("空单") }}</div>
				</div>
				<div class="m-bar">
					<div class="line">
						<div class="line-small"></div>
						<div class="line-big"></div>
					</div>
					<div class="bar-text">{{ $t("空双") }}</div>
				</div>
			</div>
			<div id="echartContainer" ref="echartContainer"
				style="width: calc(100% - 0.6rem); height: 210px; margin: 0 auto;z-index: 1;"></div>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	var echarts = require("echarts");
	export default {
		components: {},
		props: {
			id: {
				type: [Number, String],
				default: ""
			},
		},
		data() {
			return {
				data: [],
				gameList: "",
			};
		},
		methods: {
			KlineBlock(id) {
				let taht = this;
				let issue = [],
					candlist = [];
				/* this.$toast.loading({
					message: this.$t('加载中...'),
					forbidClick: true,
					duration: 0,
				}); */
				this.$http({
					method: 'get',
					data: {
						key: this.$route.query.key
					},
					url: 'getLotteryOneListNew'
				}).then((data) => {
					console.log(data.data);
					for (let i = 0; i < data.data.length; i++) {
						let num = Math.floor(Math.random() * 4)
						data.data[i].cand[3] = num + data.data[i].cand[1]
						issue.push(data.data[i].issue);
						candlist.push({
							value: data.data[i].cand,
							sum: data.data[i].cand_code,
						});
					}
					let option = {
						tooltip: {
							trigger: "axis",
							axisPointer: {
								type: "cross",
								lineStyle: {
									width: 0.9,
									opacity: 0.5,
								},
							},
							formatter: function(e) {
								console.log(e);
								return (
									"\n" +
									taht.$t("轮数") +
									":" +
									e[0].name +
									"</br>\n" +
									taht.$t("性价比") +
									":" +
									(e[0].data.sum || 0) +
									"</br>\n                   "
								);
							},
						},

						grid: {
							//直角坐标系
							top: "5%",
							left: "8%", //grid组件离容器左侧的距离
							right: "2%",
							bottom: "13%",
							//backgroundColor:'#ccc'
						},
						xAxis: {
							position: 'bottom',
							data: issue,
							splitLine: {
								show: false,
							},
							axisLine: {
								onZero: false,
							},
							axisTick: {
								//y轴刻度线
								show: true,
							},
						},
						yAxis: {
							splitLine: {
								show: false
							},
							max: 13,
							min: 0
						},
						dataZoom: [{
							type: "inside",
							xAxisIndex: [0, 1],
							start: 0,
							end: 100,
						}, ],
						series: [
							//图表类型
							{
								name: "多单",
								type: "candlestick", //K线图
								data: candlist,
								scale: false,
								axisLine: {
									onZero: false
								},
								splitLine: {
									show: false
								},
								min: "dataMin",
								max: "dataMax",
								itemStyle: {
									color: "#E31837",
									color0: "#00c47d",
									borderColor: null,
									borderColor0: null,
								},
							},
						],
					};
					var charts = echarts.init(this.$refs.echartContainer);
					charts.setOption(option);
					this.$toast.clear();
				});
			},
		},
		mounted() {
			this.KlineBlock(this.id);
		},
	};
</script>
<style lang="less" scoped>
	/* .g-header {
		position: absolute;
		width: 100%;
		height: 1.18rem;
		text-align: center;
		// background: red;
		background: rgb(14, 21, 48);
		z-index: 1;
		font-size: 0.28rem;
		color: #fff;
	} */

	.lengend {
		margin-top: 40px;
		margin-bottom: 20px;
		display: flex;
		padding: 0 30px;
	}

	.m-bar {
		display: flex;
		align-items: center;
		margin-right: 20px;
	}

	.bar-text {
		margin-left: 8px;
		font-size: 21px;
		font-family: 'nstb';
		color: #aaa;
	}

	.line {
		display: flex;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		height: 28px;
	}

	.lengend .m-bar:nth-child(1) .line>div,
	.lengend .m-bar:nth-child(3) .line>div,
	.lengend .m-bar:nth-child(4) .line>div {
		background: #E31837;
	}

	.lengend .m-bar:nth-child(1) .line>div,
	.lengend .m-bar:nth-child(3) .line>div,
	.lengend .m-bar:nth-child(4) .line>div {
		background: #E31837;
	}

	.lengend .m-bar:nth-child(1) .line>div,
	.lengend .m-bar:nth-child(3) .line>div,
	.lengend .m-bar:nth-child(4) .line>div {
		background: #E31837;
	}

	.line .line-big {
		-webkit-box-flex: 2;
		-ms-flex: 2;
		flex: 2;
		width: 8px;
	}

	.line-small {
		-webkit-box-flex: 1;
		-ms-flex: 1;
		flex: 1;
		width: 2px;
	}

	.lengend .m-bar:nth-child(3) .line,
	.lengend .m-bar:nth-child(5) .line {
		-webkit-transform: rotate(180deg);
		transform: rotate(180deg);
	}

	.lengend .m-bar:nth-child(2) .line>div,
	.lengend .m-bar:nth-child(5) .line>div,
	.lengend .m-bar:nth-child(6) .line>div {
		background: #00c47d;
	}

	.lengend .m-bar:nth-child(1) .line>div,
	.lengend .m-bar:nth-child(3) .line>div,
	.lengend .m-bar:nth-child(4) .line>div {
		background: #E31837;
	}

	// .lengend .m-bar:nth-child(1) .line > div,
	// .lengend .m-bar:nth-child(3) .line > div,
	// .lengend .m-bar:nth-child(4) .line > div {
	//   background: #df3536;
	// }
	// .lengend .m-bar:nth-child(1) .line > div,
	// .lengend .m-bar:nth-child(3) .line > div,
	// .lengend .m-bar:nth-child(4) .line > div {
	//   background: #df3536;
	// }
	// .lengend .m-bar:nth-child(2) .line > div,
	// .lengend .m-bar:nth-child(5) .line > div,
	// .lengend .m-bar:nth-child(6) .line > div {
	//   background: #35df7e;
	// }
	// .lengend .m-bar:nth-child(2) .line > div,
	// .lengend .m-bar:nth-child(5) .line > div,
	// .lengend .m-bar:nth-child(6) .line > div {
	//   background: #35df7e;
	// }
	// .lengend .m-bar:nth-child(2) .line > div,
	// .lengend .m-bar:nth-child(5) .line > div,
	// .lengend .m-bar:nth-child(6) .line > div {
	//   background: #35df7e;
	// }
	// .lengend .m-bar:nth-child(2) .line > div,
	// .lengend .m-bar:nth-child(5) .line > div,
	// .lengend .m-bar:nth-child(6) .line > div {
	//   background: #35df7e;
	// }
</style>